// App.tsx
import React from 'react';
import Heartbeat from './components/Heartbeat';
import AnimatedText from './components/AnimatedText';
import MemeGenerator from './components/MemeGenerator';
import './components/styles.css';

const App: React.FC = () => {
  return (
    <div className="monitor-container">
      {/* Heartbeat Monitor Section */}
      <div className="monitor-section heartbeat-section">
        <Heartbeat />
      </div>

      {/* Text Section */}
      <div className="monitor-section text-section">
        <AnimatedText />
      </div>

      {/* Button Section */}
      <div className="monitor-section button-section">
        <MemeGenerator />
      </div>
    </div>
  );
};

export default App;
