// Heartbeat.tsx
import React from 'react';
import './styles.css';

const Heartbeat: React.FC = () => {
  return (
    <div className="heartbeat-container">
      {/* Heartbeat SVG Line Animation */}
     
     
    </div>
  );
};

export default Heartbeat;
