// AnimatedText.tsx
import React from 'react';
import Typewriter from 'typewriter-effect';
import './styles.css';

const AnimatedText: React.FC = () => {
  return (
    <div className="animated-text">
      <h1>
        Happy Birthday to the{' '}
        <span className="typewriter-text">
          <Typewriter
            options={{
              strings: [
                'smartest',
                'most beautiful',
                'best',
                'most athletic',
                'most caring',
                'most amazing',
                'kindest',
                'cutest',
                'nicest',
                'most dedicated',
                'most disciplined',
                'most creative',
                'funniest',
                'bravest',
                'most supportive',
                'most inspiring',
                'most talented',
                'most adventurous',
                'most reliable',
                'most thoughtful',
                'most generous',
                'most understanding',
                'most patient',
                'most energetic',
                'most graceful',
                'most compassionate',
                'most optimistic',
                'most enthusiastic',
                'most loyal',
                'most hardworking',
                'most ambitious',
                'most charming',
                'most delightful',
                'most dependable',
                'most friendly',
                'most gentle',
                'most honest',
                'most innovative',
                'most loving',
                'most passionate',
                'most playful',
                'most radiant',
                'most remarkable',
                'most sensible',
                'most sincere',
                'most trustworthy',
                'most uplifting',
                'most vibrant',
                'most witty',
                'most youthful',
                'most fearless',
                'most hilarious',
                'most stylish',
                'most helpful',
                'most brilliant',
                'most joyful',
                'most courageous',
                'most artistic',
                'most wise',
                'most spontaneous',
                'most elegant',
                'most extraordinary',
                'most phenomenal',
                'most dazzling',
                'most fabulous',
                'most gracious',
                'most selfless',
                'most dedicated',
                'most dynamic',
                'most exceptional',
                'most impeccable',
                'most jovial',
                'most kind-hearted',
                'most marvelous',
                'most nurturing',
                'most outstanding',
                'most quick-witted',
                'most remarkable',
                'most sensational',
                'most talented',
                'most understanding',
                'most vivacious',
                'most wonderful',
                'most zealous',
              ],
              autoStart: true,
              loop: true,
              delay: 75,
              deleteSpeed: 50,
            }}
          />
        </span>{' '}
        sister in the world, <span className="maryam-name">MARYAM!</span>
      </h1>
    </div>
  );
};

export default AnimatedText;
