// Import useEffect
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TOTAL_MEMES = 1000; // Define the total number of memes

const MemeGenerator: React.FC = () => {
  // Initialize memesRemaining from Local Storage or default to TOTAL_MEMES
  const [memesRemaining, setMemesRemaining] = useState<number>(() => {
    const savedMemes = localStorage.getItem('memesRemaining');
    return savedMemes !== null ? parseInt(savedMemes, 10) : TOTAL_MEMES;
  });

  const [memeUrl, setMemeUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const isButtonDisabled = memesRemaining === 0 || loading;

  // Update Local Storage whenever memesRemaining changes
  useEffect(() => {
    localStorage.setItem('memesRemaining', memesRemaining.toString());
  }, [memesRemaining]);

  const IMGFLIP_USERNAME = process.env.REACT_APP_IMGFLIP_USERNAME || '';
  const IMGFLIP_PASSWORD = process.env.REACT_APP_IMGFLIP_PASSWORD || '';

  const generateMeme = async () => {
    if (memesRemaining === 0) return;

    setLoading(true);
    setError('');

    try {
      // Step 1: Request meme text from backend
      const themes = [
        'soccer',
        'medicine',
        'chess',
        'Harry Potter',
        "Grey's Anatomy",
        'middle child jokes',
        'younger and older sister dynamics',
        'having a much younger brother who gets all the attention',
        'siblings aged 21, 19, and 4',
        'being secretive about writing fanfics',
        'funny search history moments',
        'love for Draco and Hermione fanfics',
        'obsession with Marvel, especially Peter Parker and Tony Stark',
        'inside jokes about siblings',
      ];
      const randomTheme = themes[Math.floor(Math.random() * themes.length)];
      console.log(`${process.env.REACT_APP_BACKEND_URL}/generate-meme-text`);
      const memeTextResponse = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/generate-meme-text`,
        { theme: randomTheme }
      );
      

      if (!memeTextResponse.data.success) {
        throw new Error(memeTextResponse.data.error);
      }

      const memeText = memeTextResponse.data.text;

      // Step 2: Generate the meme using Imgflip /automeme endpoint
      const params = new URLSearchParams();
      params.append('username', IMGFLIP_USERNAME);
      params.append('password', IMGFLIP_PASSWORD);
      params.append('text', memeText);
      params.append('no_watermark', '1');

      const automemeResponse = await axios.post(
        'https://api.imgflip.com/automeme',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      if (automemeResponse.data.success) {
        setMemeUrl(automemeResponse.data.data.url);
        setMemesRemaining((prev) => prev - 1); // Decrease memesRemaining
      } else {
        console.error('Imgflip Error:', automemeResponse.data.error_message);
        setError(`Imgflip Error: ${automemeResponse.data.error_message}`);
      }
    } catch (err: any) {
      console.error('Error:', err);
      setError(`An error occurred: ${err.message}`);
    }

    setLoading(false);
  };

  return (
    <div>
      <button
        className="button"
        onClick={generateMeme}
        disabled={isButtonDisabled}
        style={{ backgroundColor: isButtonDisabled ? 'grey' : '#21b753' }}
      >
        {loading ? 'Generating...' : 'Press Me'}
      </button>
      <br />
      <br />
      <br />
      <div className="memes-remaining">
        Memes Remaining: {memesRemaining}/{TOTAL_MEMES}
      </div>
      {error && <div className="error-message">{error}</div>}
      {memeUrl && <img src={memeUrl} alt="Generated Meme" className="meme" />}
      
      
      
    </div>
  );
};

export default MemeGenerator;
